@import "src/general/styles";

.page-footer{
  padding: 40px 0px;
  width: 100%;
  background-color: rgb(245,245,245);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  a{
    &:hover{
      opacity: 0.7;
    }
  }

  .boundary{
    display: flex;
    //flex-direction: column;
    //align-items: flex-end;
  }

  .company-info{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    img{
      height: 40px;
    }
    p{
      margin: 8px 0px;
      font-weight: bold;
    }
    .company-info-item{
      display: flex;
      gap: 12px;
      span{
        font-size: 13px;
      }
      .label{
        opacity: 0.5;
      }
    }
  }

  .sns-n-legal{
    display: flex;
    flex-direction: column;

    .sns-container{
      display: flex;
      gap: 12px;
      a{
        font-size: 24px;
      }
    }
    .legal{
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 4px;
      a{
        font-size: 13px;
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .page-footer{
    .boundary{
      width: 100% !important;
      flex-direction: column;
      align-items: center;

      .company-info{
        align-items: center;
        padding-bottom: 24px;
      }
      .sns-n-legal{
        align-items: center;

        .sns-container{
          $border-style: 1px solid rgb(200,200,200);
          border-top: $border-style;
          border-bottom: $border-style;
        }

        .legal{
          align-items: center;
        }
      }
    }
  }
}

