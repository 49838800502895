@import "src/general/styles";

.dropdown.select-team{
  .dropdown-header{
    padding: 16px;
    border-bottom: 1px solid rgb(235,235,235);
    display: flex;
    gap: 12px;

    .btn-close{
      font-size: 14px;
      display: none;
    }

    input{
      flex: 1;
      font-size: 14px;
    }
  }
  .dropdown-body{
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow: auto;

    .empty{
      width: 100%;
      text-align: center;
      color: rgb(150,150,150);
      font-size: 14px;
    }
    .btn-item{
      &.applied{
        p{color: $red;}
      }

      $emblem-size: 32px;
      .emblem{
        width: $emblem-size;
        height: $emblem-size;
        background-color: rgb(245,245,245);
      }
      p{
        flex: 1;
        text-align: left !important;
        font-size: 14px;
      }
      span{
        line-height: 20px;
        border-radius: 12px;
        padding: 0px 6px;

        background-color: $red;
        color: white;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .item-wrapper.team{
    position: initial !important;
    .dropdown.select-team{
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;

      .dropdown-header{
        .btn-close{
          display: block;
        }
      }
      .dropdown-body{
        max-height: initial;
      }
    }
  }
}
