@import "src/general/styles";

.page.apply{
  padding: 24px;
  padding-bottom: 80px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .buffer-backdrop{
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.2);
  }

  .header{
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .intro{
      width: 100%;
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: space-between;

      img{
        height: 40px;
      }
    }


    $title-line-height: 1.4;
    h1{
      margin-top: 16px;
      font-size: 28px;
      line-height: $title-line-height;
      font-weight: 800;
      strong{
        color: $red;
        line-height: $title-line-height;
        font-weight: 800;
      }
    }
    span{
      margin-top: 4px;
      text-align: center;
      color: rgb(160,160,160);
    }
  }

  .body{
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;

    .item-wrapper{
      position: relative;
      width: 100%;

      .dropdown {
        position: absolute;
        z-index: 100;
        width: 100%;
        background-color: white;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        gap: 0px;

        &.hide {
          display: none;
        }

        .btn-item{
          padding-left: 16px;
          padding-right: 16px;

          width: 100%;
          font-weight:normal;
          min-height: 48px;
          align-items: center;
          background-color: transparent;

          display: flex;
          gap: 12px;

          p{
            font-size: 14px;
            width: 100%;
            text-align: center;
          }

          &:hover{
            background-color: rgb(245,245,245);
          }
        }
      }
    }

    .form-item{
      width: 100%;
      display: flex;
      min-height: 44px;
      align-items: center;
      gap: 8px;
      font-size: 14px;

      transition: background-color 200ms ease;

      *{
        font-size: 14px;
        font-weight: normal;
      }

      &:hover{
        background-color: rgb(240,240,240) !important;
      }

      &.inquiry{
        flex: 1;
        //max-height: 200px;
        min-height: 100px;
      }

      $placeholder-color: rgb(140,140,140);
      &::placeholder{
        color: $placeholder-color;
      }

      &.select{
        padding: 0px 16px;
        background-color: rgb(250,250,250);
        border: 1px solid rgb(235,235,235);

        .placeholder{
          color: $placeholder-color;
        }
        .label{
          flex: 1;
          text-align: left;
        }
        i{
          font-size: 10px;
          color: $red;
        }
      }
    }

    .container-form-items{
      display: flex;
      gap: 12px;
    }
    .btn-wrapper{
      width: 100%;
      display: flex;
      background-color: white;

      .btn-apply{
        flex: 1;
        height: 48px;
        &:disabled{
          opacity: 0.3;
          pointer-events: none;
        }
      }
    }

  }
}

@media (max-width: $breakpoint-tablet) {
  .page.apply{
    padding-top: 0px;
    padding-bottom: 24px;
    .header{
      h1{
        font-size: 25px;
      }

    }
  }
}
