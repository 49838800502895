/**
customicons Webfont
*/
@font-face {
  font-family: 'customicons';
  src: url('customicons.eot?1664543255314');
  src: url('customicons.eot?#iefix-1664543255314') format('embedded-opentype'),
  url('customicons.woff2?1664543255314') format('woff2'),
  url('customicons.woff?1664543255314') format('woff'),
  url('customicons.ttf?1664543255314') format('truetype');
  font-weight: normal;
  font-style: normal;
}
[class^='bepro-']:before,
[class*='bepro-']:before {
  font-family: 'customicons';
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /*text-rendering: optimizeLegibility;*/
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



/*
Glyphs list
*/

.bepro-folder:before {  content: '\ea01';  }
.bepro-folder-plus:before {  content: '\ea02';  }
.bepro-folder-save:before {  content: '\ea03';  }
.bepro-note-plus:before {  content: '\ea04';  }
.bepro-note-solid:before {  content: '\ea05';  }
.bepro-note:before {  content: '\ea06';  }
.bepro-folder-solid:before {  content: '\ea07';  }
.bepro-link:before {  content: '\ea08';  }
.bepro-unlink:before {  content: '\ea09';  }
.bepro-prev-folder:before {  content: '\ea0a';  }
.bepro-eraser:before {  content: '\ea0b';  }
.bepro-pencil:before {  content: '\ea0c';  }
.bepro-brush:before {  content: '\ea0d';  }
.bepro-move:before {  content: '\ea0e';  }
.bepro-hand:before {  content: '\ea0f';  }
.bepro-hand-grab:before {  content: '\ea10';  }
.bepro-expand:before {  content: '\ea11';  }
.bepro-sound:before {  content: '\ea12';  }
.bepro-play-back-rate:before {  content: '\ea13';  }
.bepro-zoom-in:before {  content: '\ea14';  }
.bepro-zoom-out:before {  content: '\ea15';  }
.bepro-share:before {  content: '\ea16';  }
.bepro-log-out:before {  content: '\ea17';  }
.bepro-folder-transfer:before {  content: '\ea18';  }
.bepro-people-plus:before {  content: '\ea19';  }
.bepro-description:before {  content: '\ea1a';  }
.bepro-defense-rate-solid:before {  content: '\ea1b';  }
.bepro-add-video:before {  content: '\ea1c';  }
.bepro-document:before {  content: '\ea1d';  }
.bepro-team:before {  content: '\ea1e';  }
.bepro-team-solid:before {  content: '\ea1f';  }
.bepro-creditcard:before {  content: '\ea20';  }
.bepro-paypal:before {  content: '\ea21';  }
.bepro-money-transfer:before {  content: '\ea22';  }
.bepro-invoice:before {  content: '\ea23';  }
.bepro-invoice-solid:before {  content: '\ea24';  }
.bepro-copy:before {  content: '\ea25';  }
.bepro-ball:before {  content: '\ea26';  }
.bepro-card:before {  content: '\ea27';  }
.bepro-team-join:before {  content: '\ea28';  }
.bepro-player-fw:before {  content: '\ea29';  }
.bepro-player-fw-shirt:before {  content: '\ea2a';  }
.bepro-player-fw-pants:before {  content: '\ea2b';  }
.bepro-player-fw-socks:before {  content: '\ea2c';  }
.bepro-player-mf:before {  content: '\ea2d';  }
.bepro-player-mf-shirt:before {  content: '\ea2e';  }
.bepro-player-mf-pants:before {  content: '\ea2f';  }
.bepro-player-mf-socks:before {  content: '\ea30';  }
.bepro-player-df:before {  content: '\ea31';  }
.bepro-player-df-shirt:before {  content: '\ea32';  }
.bepro-player-df-pants:before {  content: '\ea33';  }
.bepro-player-df-socks:before {  content: '\ea34';  }
.bepro-player-gk:before {  content: '\ea35';  }
.bepro-player-gk-shirt:before {  content: '\ea36';  }
.bepro-player-gk-pants:before {  content: '\ea37';  }
.bepro-player-gk-socks:before {  content: '\ea38';  }
.bepro-football-shoe:before {  content: '\ea39';  }
.bepro-football-glove:before {  content: '\ea3a';  }
.bepro-online:before {  content: '\ea3b';  }
.bepro-offline:before {  content: '\ea3c';  }
.bepro-cloud-download:before {  content: '\ea3d';  }
.bepro-merge:before {  content: '\ea3e';  }
.bepro-desktop:before {  content: '\ea3f';  }
.bepro-mobile:before {  content: '\ea40';  }
.bepro-send:before {  content: '\ea41';  }
.bepro-save:before {  content: '\ea42';  }
.bepro-duplicate:before {  content: '\ea43';  }
.bepro-resize-top-right:before {  content: '\ea44';  }
.bepro-window-enlarge:before {  content: '\ea45';  }
.bepro-ground:before {  content: '\ea46';  }
.bepro-logo:before {  content: '\ea47';  }
.bepro-logo-solid:before {  content: '\ea48';  }
.bepro-league:before {  content: '\ea49';  }
.bepro-drawing-tools:before {  content: '\ea4a';  }
.bepro-draw-circle:before {  content: '\ea4b';  }
.bepro-draw-rect:before {  content: '\ea4c';  }
.bepro-draw-arrow:before {  content: '\ea4d';  }
.bepro-draw-move:before {  content: '\ea4e';  }
.bepro-draw-rect-striped:before {  content: '\ea4f';  }
.bepro-data:before {  content: '\ea50';  }
.bepro-data-solid:before {  content: '\ea51';  }
.bepro-alert:before {  content: '\ea52';  }
.bepro-alert-solid:before {  content: '\ea53';  }
.bepro-video-cam:before {  content: '\ea54';  }
.bepro-video-cam-solid:before {  content: '\ea55';  }
.bepro-gear:before {  content: '\ea56';  }
.bepro-search:before {  content: '\ea57';  }
.bepro-check-mark:before {  content: '\ea58';  }
.bepro-check-mark-round:before {  content: '\ea59';  }
.bepro-check-mark-round-solid:before {  content: '\ea5a';  }
.bepro-location:before {  content: '\ea5b';  }
.bepro-time:before {  content: '\ea5c';  }
.bepro-download:before {  content: '\ea5d';  }
.bepro-person:before {  content: '\ea5e';  }
.bepro-people:before {  content: '\ea5f';  }
.bepro-person-solid:before {  content: '\ea60';  }
.bepro-people-solid:before {  content: '\ea61';  }
.bepro-more:before {  content: '\ea62';  }
.bepro-hamburger:before {  content: '\ea63';  }
.bepro-drawer:before {  content: '\ea64';  }
.bepro-arrow-undo:before {  content: '\ea65';  }
.bepro-arrow-redo:before {  content: '\ea66';  }
.bepro-plus:before {  content: '\ea67';  }
.bepro-plus-round:before {  content: '\ea68';  }
.bepro-close:before {  content: '\ea69';  }
.bepro-close-round:before {  content: '\ea6a';  }
.bepro-info:before {  content: '\ea6b';  }
.bepro-info-round:before {  content: '\ea6c';  }
.bepro-graph-bar:before {  content: '\ea6d';  }
.bepro-graph-bar-solid:before {  content: '\ea6e';  }
.bepro-upload:before {  content: '\ea6f';  }
.bepro-filter:before {  content: '\ea70';  }
.bepro-filter-solid:before {  content: '\ea71';  }
.bepro-star:before {  content: '\ea72';  }
.bepro-star-solid:before {  content: '\ea73';  }
.bepro-bell:before {  content: '\ea74';  }
.bepro-bell-solid:before {  content: '\ea75';  }
.bepro-chat:before {  content: '\ea76';  }
.bepro-chat-solid:before {  content: '\ea77';  }
.bepro-email:before {  content: '\ea78';  }
.bepro-folder-checked:before {  content: '\ea79';  }
.bepro-folder-checked-solid:before {  content: '\ea7a';  }
.bepro-trash-can:before {  content: '\ea7b';  }
.bepro-arrow-a-right:before {  content: '\ea7c';  }
.bepro-arrow-a-up:before {  content: '\ea7d';  }
.bepro-arrow-a-down:before {  content: '\ea7e';  }
.bepro-arrow-a-left:before {  content: '\ea7f';  }
.bepro-arrow-b-right:before {  content: '\ea80';  }
.bepro-arrow-b-up:before {  content: '\ea81';  }
.bepro-arrow-b-down:before {  content: '\ea82';  }
.bepro-arrow-b-left:before {  content: '\ea83';  }
.bepro-reorder:before {  content: '\ea84';  }
.bepro-history:before {  content: '\ea85';  }
.bepro-cloud-upload:before {  content: '\ea86';  }
.bepro-layout:before {  content: '\ea87';  }
.bepro-heart:before {  content: '\ea88';  }
.bepro-heart-solid:before {  content: '\ea89';  }
.bepro-comment:before {  content: '\ea8a';  }
.bepro-comment-solid:before {  content: '\ea8b';  }
.bepro-thumbs-up:before {  content: '\ea8c';  }
.bepro-develop:before {  content: '\ea8d';  }
.bepro-eye:before {  content: '\ea8e';  }
.bepro-camera-fixed:before {  content: '\ea8f';  }
.bepro-scissor:before {  content: '\ea90';  }
.bepro-scissor-solid:before {  content: '\ea91';  }
.bepro-calendar:before {  content: '\ea92';  }
.bepro-calendar-solid:before {  content: '\ea93';  }
.bepro-mobile-rotate:before {  content: '\ea94';  }
.bepro-auto-video:before {  content: '\ea95';  }
.bepro-arrow-c-right:before {  content: '\ea96';  }
.bepro-arrow-c-up:before {  content: '\ea97';  }
.bepro-arrow-c-down:before {  content: '\ea98';  }
.bepro-arrow-c-left:before {  content: '\ea99';  }
.bepro-question-mark:before {  content: '\ea9a';  }
.bepro-question-mark-round:before {  content: '\ea9b';  }
.bepro-camera:before {  content: '\ea9c';  }
.bepro-camera-refresh:before {  content: '\ea9d';  }
.bepro-refresh:before {  content: '\ea9e';  }
.bepro-record:before {  content: '\ea9f';  }
.bepro-record-stop:before {  content: '\eaa0';  }
.bepro-more-vertical:before {  content: '\eaa1';  }
.bepro-shortcut-key:before {  content: '\eaa2';  }
.bepro-view-2d:before {  content: '\eaa3';  }
.bepro-view-3d:before {  content: '\eaa4';  }
.bepro-draw-arrow-dashed:before {  content: '\eaa5';  }
.bepro-draw-arrow-zigzag:before {  content: '\eaa6';  }
.bepro-draw-text:before {  content: '\eaa7';  }
.bepro-manual-video:before {  content: '\eaa8';  }
.bepro-enter-full-screen:before {  content: '\eaa9';  }
.bepro-exit-full-screen:before {  content: '\eaaa';  }
.bepro-draw-broken-line:before {  content: '\eaab';  }
.bepro-view-360:before {  content: '\eaac';  }
.bepro-draw-player-marker:before {  content: '\eaad';  }
.bepro-video-hd:before {  content: '\eaae';  }
.bepro-view-panorama:before {  content: '\eaaf';  }
.bepro-whistle:before {  content: '\eab0';  }
.bepro-whistle-solid:before {  content: '\eab1';  }
.bepro-cap:before {  content: '\eab2';  }
.bepro-cap-solid:before {  content: '\eab3';  }
.bepro-video-play:before {  content: '\eab4';  }
.bepro-video-pause:before {  content: '\eab5';  }
.bepro-video-play-solid:before {  content: '\eab6';  }
.bepro-video-pause-solid:before {  content: '\eab7';  }
.bepro-shirt:before {  content: '\eab8';  }
.bepro-shirt-solid:before {  content: '\eab9';  }
.bepro-draw-arrow-curved:before {  content: '\eaba';  }
.bepro-exclamation-mark:before {  content: '\eabb';  }
.bepro-exclamation-mark-round:before {  content: '\eabc';  }
.bepro-arrow-d-right:before {  content: '\eabd';  }
.bepro-arrow-d-up:before {  content: '\eabe';  }
.bepro-arrow-d-down:before {  content: '\eabf';  }
.bepro-arrow-d-left:before {  content: '\eac0';  }
.bepro-eye-off:before {  content: '\eac1';  }
.bepro-lock:before {  content: '\eac2';  }
.bepro-lock-solid:before {  content: '\eac3';  }
.bepro-minus:before {  content: '\eac4';  }
.bepro-minus-round:before {  content: '\eac5';  }
.bepro-replay:before {  content: '\eac6';  }
.bepro-replay-solid:before {  content: '\eac7';  }
.bepro-language:before {  content: '\eac8';  }
.bepro-world:before {  content: '\eac9';  }
.bepro-list:before {  content: '\eaca';  }
.bepro-gear-solid:before {  content: '\eacb';  }
.bepro-sound-solid:before {  content: '\eacc';  }
.bepro-play-back-rate-solid:before {  content: '\eacd';  }
.bepro-custom-view:before {  content: '\eace';  }
.bepro-analyse:before {  content: '\eacf';  }
.bepro-exclamation-mark-solid:before {  content: '\ead0';  }
.bepro-box-fall:before {  content: '\ead1';  }
.bepro-vs:before {  content: '\ead2';  }
.bepro-move-out:before {  content: '\ead3';  }
.bepro-files-piled:before {  content: '\ead4';  }
.bepro-switch:before {  content: '\ead5';  }
.bepro-analyse-disabled:before {  content: '\ead6';  }
.bepro-side-bar-minimize:before {  content: '\ead7';  }
.bepro-side-bar-expand:before {  content: '\ead8';  }
.bepro-live:before {  content: '\ead9';  }
.bepro-head-phone:before {  content: '\eada';  }
.bepro-flag:before {  content: '\eadb';  }
.bepro-staff:before {  content: '\eadc';  }
.bepro-shopping-cart:before {  content: '\eadd';  }
.bepro-unlock:before {  content: '\eade';  }
.bepro-unlock-solidsvg:before {  content: '\eadf';  }
.bepro-credit:before {  content: '\eae0';  }
.bepro-download-with-credit:before {  content: '\eae1';  }
.bepro-load:before {  content: '\eae2';  }
.bepro-analysis-credit:before {  content: '\eae3';  }
.bepro-recording-credit:before {  content: '\eae4';  }
.bepro-project-check:before {  content: '\eae5';  }
.bepro-caution:before {  content: '\eae6';  }
.bepro-label:before {  content: '\eae7';  }
.bepro-signal:before {  content: '\eae8';  }
.bepro-signal-weak:before {  content: '\eae9';  }
.bepro-key-mac-shift:before {  content: '\eaea';  }
.bepro-key-mac-command:before {  content: '\eaeb';  }
.bepro-key-mac-option:before {  content: '\eaec';  }
.bepro-video-play-round:before {  content: '\eaed';  }
.bepro-video-play-round-solid:before {  content: '\eaee';  }
.bepro-video-pause-round:before {  content: '\eaef';  }
.bepro-change-half-out:before {  content: '\eaf0';  }
.bepro-change-half-in:before {  content: '\eaf1';  }
.bepro-tutorial-video:before {  content: '\eaf2';  }
.bepro-megaphone:before {  content: '\eaf3';  }
.bepro-question:before {  content: '\eaf4';  }
.bepro-denied:before {  content: '\eaf5';  }
.bepro-image:before {  content: '\eaf6';  }
.bepro-edited-clip:before {  content: '\eaf7';  }
.bepro-edited-clip-line:before {  content: '\eaf8';  }
.bepro-label-solid:before {  content: '\eaf9';  }
.bepro-matrix:before {  content: '\eafa';  }
.bepro-book-mark:before {  content: '\eafb';  }
.bepro-view-scouting:before {  content: '\eafc';  }
.bepro-scene:before {  content: '\eafd';  }
.bepro-download-clips:before {  content: '\eafe';  }
.bepro-custom-order:before {  content: '\eaff';  }
.bepro-home:before {  content: '\eb00';  }
.bepro-add-label:before {  content: '\eb01';  }
.bepro-add-track:before {  content: '\eb02';  }
.bepro-load-tracks:before {  content: '\eb03';  }
.bepro-save-tracks:before {  content: '\eb04';  }
.bepro-lightning:before {  content: '\eb05';  }
.bepro-quick-creation:before {  content: '\eb06';  }
.bepro-track-list:before {  content: '\eb07';  }
.bepro-add-track-list:before {  content: '\eb08';  }
.bepro-folder-shared:before {  content: '\eb09';  }
.bepro-folder-shared-solid:before {  content: '\eb0a';  }
.bepro-folders:before {  content: '\eb0b';  }
.bepro-arrow-e-up:before {  content: '\eb0c';  }
.bepro-arrow-e-right:before {  content: '\eb0d';  }
.bepro-arrow-e-down:before {  content: '\eb0e';  }
.bepro-arrow-e-left:before {  content: '\eb0f';  }
.bepro-quick-creation-solid:before {  content: '\eb10';  }
.bepro-folders-shared:before {  content: '\eb11';  }
.bepro-new:before {  content: '\eb12';  }
.bepro-new-solid:before {  content: '\eb13';  }
.bepro-drag-video-file:before {  content: '\eb14';  }
.bepro-retry:before {  content: '\eb15';  }
.bepro-folder-fluid:before {  content: '\eb16';  }
.bepro-folder-open:before {  content: '\eb17';  }
.bepro-bug:before {  content: '\eb18';  }
.bepro-signal-alert:before {  content: '\eb19';  }
.bepro-custom-view-s:before {  content: '\eb1a';  }
.bepro-exclamation-mark-s:before {  content: '\eb1b';  }
.bepro-chat-question:before {  content: '\eb1c';  }
.bepro-folder-slash:before {  content: '\eb1d';  }
.bepro-tracks-gear:before {  content: '\eb1e';  }
.bepro-check-thick:before {  content: '\eb1f';  }
.bepro-star-plus:before {  content: '\eb20';  }
.bepro-ground-signal:before {  content: '\eb21';  }
.bepro-time-refresh-bold:before {  content: '\eb22';  }
.bepro-upgrade:before {  content: '\eb23';  }
.bepro-sort:before {  content: '\eb24';  }
.bepro-line-linked:before {  content: '\eb25';  }
.bepro-line-linked-open:before {  content: '\eb26';  }
.bepro-line-linked-close:before {  content: '\eb27';  }
.bepro-line-horizontal-bold:before {  content: '\eb28';  }
.bepro-line-horizontal-dashed-bold:before {  content: '\eb29';  }
.bepro-measure:before {  content: '\eb2a';  }
.bepro-line-linked-fill:before {  content: '\eb2b';  }
.bepro-spotlight:before {  content: '\eb2c';  }
.bepro-spinner:before {  content: '\eb2d';  }
.bepro-trace:before {  content: '\eb2e';  }
.bepro-more-vertical-bold:before {  content: '\eb2f';  }
.bepro-spoid:before {  content: '\eb30';  }
.bepro-target-person:before {  content: '\eb31';  }
.bepro-restore-data:before {  content: '\eb32';  }
.bepro-stop-watch:before {  content: '\eb33';  }
.bepro-message:before {  content: '\eb34';  }
.bepro-graph-line-rise:before {  content: '\eb35';  }
.bepro-gift:before {  content: '\eb36';  }
.bepro-gift-fill:before {  content: '\eb37';  }
.bepro-arrow-f-up:before {  content: '\eb38';  }
.bepro-arrow-f-down:before {  content: '\eb39';  }
.bepro-shield-check:before {  content: '\eb3a';  }
.bepro-shield-check-fill:before {  content: '\eb3b';  }
.bepro-shield-disabled:before {  content: '\eb3c';  }
.bepro-os-apple:before {  content: '\eb3d';  }
.bepro-facebook-round-fill:before {  content: '\eb3e';  }
.bepro-facebook-rounded-rect-fill:before {  content: '\eb3f';  }
.bepro-youtube-fill:before {  content: '\eb40';  }
.bepro-instagram-fill:before {  content: '\eb41';  }
.bepro-twitter-fill:before {  content: '\eb42';  }
.bepro-os-windows:before {  content: '\eb43';  }
.bepro-os-android:before {  content: '\eb44';  }
.bepro-folder-files:before {  content: '\eb45';  }
.bepro-table-column:before {  content: '\eb46';  }
.bepro-scale:before {  content: '\eb47';  }
.bepro-prev-video:before {  content: '\eb48';  }
.bepro-next-video:before {  content: '\eb49';  }
.bepro-devices:before {  content: '\eb4a';  }
.bepro-download-underline:before {  content: '\eb4b';  }
.bepro-average:before {  content: '\eb4c';  }
.bepro-team-plus:before {  content: '\eb4d';  }
.bepro-pin:before {  content: '\eb4e';  }
.bepro-pin-fill:before {  content: '\eb4f';  }
.bepro-person-plus:before {  content: '\eb50';  }
.bepro-league-plus:before {  content: '\eb51';  }
.bepro-document-plus:before {  content: '\eb52';  }
.bepro-chat-bubble:before {  content: '\eb53';  }
.bepro-chat-bubble-fill:before {  content: '\eb54';  }
.bepro-document-slash:before {  content: '\eb55';  }
.bepro-document-arrow-in:before {  content: '\eb56';  }
.bepro-chat-bubble-arrow-in:before {  content: '\eb57';  }
.bepro-scribble:before {  content: '\eb58';  }
.bepro-scribble-bold:before {  content: '\eb59';  }
.bepro-bell-slash-fill:before {  content: '\eb5a';  }
.bepro-bell-slash:before {  content: '\eb5b';  }
.bepro-chat-bubble-dashed:before {  content: '\eb5c';  }
.bepro-linkedin-fill:before {  content: '\eb5d';  }
.bepro-play-round-fill-slash:before {  content: '\eb5e';  }
.bepro-fold-horizontal-middle:before {  content: '\eb5f';  }
.bepro-unfold-horizontal-middle:before {  content: '\eb60';  }
.bepro-fold-horizontal-bottom:before {  content: '\eb61';  }
.bepro-unfold-horizontal-bottom:before {  content: '\eb62';  }
.bepro-small-to-big-rect-right:before {  content: '\eb63';  }
.bepro-big-to-small-rect-left:before {  content: '\eb64';  }
.bepro-pencil-writing:before {  content: '\eb65';  }
.bepro-arrow-turn-top-left-bold:before {  content: '\eb66';  }
.bepro-arrow-turn-top-right-bold:before {  content: '\eb67';  }
.bepro-arrow-turn-top-left:before {  content: '\eb68';  }
.bepro-arrow-turn-top-right:before {  content: '\eb69';  }
.bepro-arrow-bottom-right:before {  content: '\eb6a';  }
.bepro-arrow-bottom-left:before {  content: '\eb6b';  }
.bepro-cloud:before {  content: '\eb6c';  }
.bepro-cloud-slash:before {  content: '\eb6d';  }
.bepro-time-alert:before {  content: '\eb6e';  }
.bepro-check-octa-fill:before {  content: '\eb6f';  }
.bepro-fold-vertical-right:before {  content: '\eb70';  }
.bepro-unfold-vertical-right:before {  content: '\eb71';  }
.bepro-speed-meter:before {  content: '\eb72';  }
.bepro-check-mark-rect-multiple:before {  content: '\eb73';  }
.bepro-dots-six:before {  content: '\eb74';  }
.bepro-custom-view-plus:before {  content: '\eb75';  }
.bepro-crop-plus:before {  content: '\eb76';  }
.bepro-rect-corner-frame-outward:before {  content: '\eb77';  }
.bepro-rect-corner-frame-outward-plus:before {  content: '\eb78';  }
.bepro-record-plus:before {  content: '\eb79';  }
.bepro-document-arrow-down:before {  content: '\eb7a';  }
.bepro-document-arrow-up:before {  content: '\eb7b';  }
.bepro-folders-fill:before {  content: '\eb7c';  }
.bepro-folders-shared-fill:before {  content: '\eb7d';  }
.bepro-clips:before {  content: '\eb7e';  }
.bepro-clips-right:before {  content: '\eb7f';  }
.bepro-clips-left:before {  content: '\eb80';  }
.bepro-clips-between:before {  content: '\eb81';  }
.bepro-custom-view-slash:before {  content: '\eb82';  }
.bepro-hard-disk:before {  content: '\eb83';  }
.bepro-hard-disk-fill:before {  content: '\eb84';  }
.bepro-hard-disk-fill-warning:before {  content: '\eb85';  }
.bepro-arrow-round-top-left:before {  content: '\eb86';  }
.bepro-arrow-round-top-left-n:before {  content: '\eb87';  }
.bepro-ground-horizontal:before {  content: '\eb88';  }
.bepro-video-cam-check:before {  content: '\eb89';  }
.bepro-document-video:before {  content: '\eb8a';  }
.bepro-cone:before {  content: '\eb8b';  }
.bepro-cone-fill:before {  content: '\eb8c';  }
.bepro-custom-view-minus:before {  content: '\eb8d';  }
.bepro-rect-top-left-detach:before {  content: '\eb8e';  }
.bepro-rect-top-left-attach:before {  content: '\eb8f';  }
.bepro-circle-move-top-right:before {  content: '\eb90';  }
.bepro-ground-horizontal-check:before {  content: '\eb91';  }
.bepro-ground-horizontal-alert:before {  content: '\eb92';  }
.bepro-km:before {  content: '\eb93';  }
.bepro-table:before {  content: '\eb94';  }
.bepro-key:before {  content: '\eb95';  }
.bepro-polygon:before {  content: '\eb96';  }
.bepro-person-standing:before {  content: '\eb97';  }
.bepro-cloud-fill:before {  content: '\eb98';  }
.bepro-cloud-slash-fill:before {  content: '\eb99';  }
.bepro-presentation:before {  content: '\eb9a';  }
.bepro-presentation-plus:before {  content: '\eb9b';  }
.bepro-presentation-arrow-in:before {  content: '\eb9c';  }
.bepro-cerberus:before {  content: '\eb9d';  }
.bepro-more-bold:before {  content: '\eb9e';  }
.bepro-rect-grid-2x2:before {  content: '\eb9f';  }
.bepro-team-clock:before {  content: '\eba0';  }
.bepro-login:before {  content: '\eba1';  }
.bepro-video-play-round-dashed:before {  content: '\eba2';  }
.bepro-video-cam-dashed:before {  content: '\eba3';  }
.bepro-crown:before {  content: '\eba4';  }
.bepro-crown-fill:before {  content: '\eba5';  }
