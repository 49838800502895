@import "src/general/styles";

.page.apply.completed{
  height: initial;

  .header{
    p{
      margin-top: 16px;
      color: $red;
      text-align: center;
      font-size: 14px;
      word-break: keep-all;
      &.sub{
        color: $sub-content;
      }
    }
  }

  .body{
    .applied-info{
      margin: 16px 0px;
      border-top: 2px solid rgb(235,235,235);
      display: flex;
      flex-direction: column;

      .applied-data-item{
        padding: 12px 0px;
        //align-items: ;
        justify-content: space-between;
        border-bottom: 1px solid rgb(235,235,235);
        display: flex;
        gap: 12px;

        *{
          font-size: 14px;
        }

        .label{
          opacity: 0.5;
        }

        .value{
          flex: 1;
          text-align: right;
          white-space: pre-wrap;
        }
      }
    }
  }
}
