@import "src/general/styles";

$fixed-header-height: 60px;
$content-width : 550px;
.page.main{
  display: flex;
  flex-direction: column;
  align-items: center;

  .pre-header{
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    height: $fixed-header-height;
    background-color: white;
    border-bottom: 1px solid rgb(245,245,245);

    img{
      height: 40px;
    }
  }

  .header {
    position: relative;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    background: url("../img/header-back.png") center no-repeat;
    background-size: cover;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      content: "";
    }

    * {
      position: relative;
      z-index: 100;
    }

    img{
      height: 40px;
    }

    h1{
      //white-space: pre-line;
      text-align: center;
      color: white;
    }

    .receiver{
      position: absolute;
      bottom: 0;

      padding: 0px 8px;
      background-color: $red;
      color: white;
      font-weight: bold;

      transform: translateY(50%);
    }
  }

  .body{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    .boundary{
      padding: 20px;
      max-width: $content-width;
      width: 100%;
    }
    .divider{
      position: relative;
      //height: 60px;
      width: 100%;

      &::before{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;

        content: "";

        height: 2px;
        width: 100px;
        background-color: rgb(225,225,225);
      }
    }

    .content{
      padding: 40px 0px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      p{
        word-break: keep-all;
      }


      &.action-needed{
        .container-packages{
          padding: 20px 8px;
          display: flex;
          gap: 24px;
          justify-content: center;

          .package-item{
            flex: 1;
          }
        }
      }

      .sub-title{
        width: 100%;
        text-align: center;
        font-size: 28px;
        font-weight: 800;
        margin-bottom: 12px;
      }
    }

    strong{
      color: red;
      &.sub{
        color: black !important;
      }
    }
  }
  .page-footer{
    .boundary{
      width: $content-width;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .page.main{
    position: relative;
    padding-top: $fixed-header-height;

    .pre-header{
      display: flex;
    }
    .header{
      height: 150px;
      img{
        display: none;
      }
      h1{
        font-size: 25px;
        white-space: pre-line;
        line-height: 1.4;
      }
    }
    .body{
      .content{
        &.action-needed{
          .container-packages{
            padding-left: 0px;
            padding-right: 0px;
          }
        }
      }
    }
  }
}

