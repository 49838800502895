@import "../../general/styles";

.package-item{
  background-color: rgb(250,250,250);
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;

  transition: border-color 200ms ease;

  &:hover{
    border-color: $red;
  }

  .package-item-header{
    padding: 8px 24px;

    flex: 1;
    background-color: rgb(240,240,240);
    //border-bottom: 2px solid rgb(230,230,230);

    $title-line-height: 48px;
    .title{
      display: flex;
      gap: 12px;
      align-items: center;
      *{
        line-height: $title-line-height;
        color: $red;
      }
      i{
        font-size: 25px;
      }
      h2{
        font-size: 25px;
      }
    }
  }

  .package-item-body{
    padding: 12px 24px;

    .package-info-item{
      display: flex;
      flex-direction: column;
      //align-items: flex-end;
      padding: 8px 0px;

      border-bottom: 1px solid rgb(220,220,220);

      &:last-child{
        border: none;
      }

      .main{
        display: flex;
        width: 100%;
        $dot-size: 4px;
        align-items: center;
        gap: 8px;

        font-size: 15px;

        .dot{
          width: $dot-size;
          height: $dot-size;
          border-radius: 50%;
          background-color: $main-content;
        }

        span{
          font-weight: 500;
          &.key{
            flex: 1;
          }
        }
      }
      .sub{
        padding-left: 12px;
        font-size: 14px;
        color: $red;
      }
    }
  }

  .package-item-footer{
    background-color: rgb(240,240,240);
    padding: 20px 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .price{
      display: flex;
    }

    .price-item{
      display: flex;
      flex-direction: column;

      *{
        line-height: 26px;
        font-size: 14px;
        white-space: nowrap;
      }

      .vat{
        background-color: white;
        color: $red;
        border-radius: 24px;
        padding: 0px 8px;
      }


      &.final{
        flex: 1;
        align-items: flex-end;
        .final-price{
          color: $red;
          font-size: 24px;
          font-weight: bold;
          span{
            color: $red;
            font-size: 16px;
          }
        }
        .monthly{
          color: rgb(150,150,150);
        }
      }
    }
    .container-buttons{
      display: flex;
      gap: 12px;

      .btn-apply{
        width: 100%;
        height: 52px;
        &.btn-red-outlined{
          padding: 8px 0px;
          flex-direction: column;
          height: initial !important;
          gap: 0px;
          span{
            color: $red;
            &.sub{
              margin-top: -2px;
              font-size: 13px;
              text-align: center;
              line-height: 1.2;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}


@media (max-width: $breakpoint-tablet) {
  .package-item{
    .package-item-body{
      padding-left: 16px;
      padding-right: 16px;
    }
    .package-item-footer{
      padding: 20px;

      .price{
        flex-direction: column;
        align-items: center;
        .price-item.final{
          padding-top: 12px;
          align-items: center;
        }
      }

      .container-buttons{
        flex-direction: column;
      }
    }
  }
}

