@import "src/general/styles";

*{
  box-sizing: border-box;
  color: $main-content;
  padding: 0px;
  margin: 0px;
  line-height: 1.7;
}

button{
  cursor: pointer !important;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  padding: 0px 12px;
  transition: background-color 200ms ease;
}

.btn-red-solid{
  background-color: $red;
  color: white;

  &:hover{
    background-color: red;
  }
}

.btn-red-outlined{
  border: 1px solid $red;
  background-color: white;
  color: $red;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;

  &:hover{
    background-color: rgb(235,235,235);
  }
}

input, textarea{
  padding: 8px 16px;
  border: 1px solid rgb(235,235,235);
  background-color: rgb(250,250,250);
  resize: none;
}

button, input, textarea{
  &.allow-focus:focus{
    outline: 1px solid red;
  }
}

.bg-pt{
  position: absolute;
  z-index:-1;
  opacity: 0.075;

  &.lt{
    top: 0;
    left: 0;
    width: 240px;
  }
  &.rb{
    right: 0;
    bottom: 0;
    width: 270px;
  }
}

@media (max-width: $breakpoint-tablet) {
  .bg-pt{
    &.lt{
      width: 150px;
    }

    &.rb{
      right: 0;
      bottom: 0;
      width: 180px;
    }
  }
}

