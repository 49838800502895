@mixin mixin-buffer-spinner($spinner-size, $bar-height) {
  display: inline-block;
  position: relative;
  width: $spinner-size;
  height: $spinner-size;

  $bar-width: if($bar-height > 4px, 2px, 1px);

  .buffer-stick {
    transform-origin: $spinner-size/2 $spinner-size/2;
    animation: buffer-spinner 1.2s linear infinite;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      left: ($spinner-size / 2) - ($bar-width / 2);
      width: $bar-width;
      height: $bar-height;
      background-color: inherit;
    }

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }
    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }
    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }
    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }
    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }
    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }
    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }
    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }
    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }
    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
  }
}

.x-small {
  @include mixin-buffer-spinner(24px, 4px);
}

.small {
  @include mixin-buffer-spinner(32px, 7px);
}

.medium {
  @include mixin-buffer-spinner(48px, 10px);
}

@keyframes buffer-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
